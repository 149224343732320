import React from "react"
import { Text } from "theme-ui"
import Layout from "../../components/layout"
import RoleTemplate from "../../components/roles/RoleTemplate"
import Pagination from "../../components/Pagination"

import portraitImage from "../../images/role-portraits/doctor-cutout.png"
import doctor01Image from "../../images/ability-cards/doctor-03.png"
import doctor02Image from "../../images/ability-cards/doctor-02.png"
import doctor03Image from "../../images/ability-cards/doctor-01.png"
import doctor04Image from "../../images/ability-cards/doctor-04.png"

import YAMLData from "../../data/learning-paths/doctor.yaml"

const DoctorPage = () => (
  <Layout>
    <RoleTemplate
      name="doctor"
      src={portraitImage}
      bgGradient="to bottom, #F69C93, #F9AD95"
      introDescription={
        <>
          <Text>The Doctor gently touches the arm of an ally, mending their body and spirit. They lean down to examine the dead, discovering what caused their cruel fate. They sense the departed, and speak with lost souls.</Text>
          <Text>The Doctor is a good choice for people who want to be the party’s healer, or someone who plays with the forces of life and death.</Text>
        </>
      }
      imgWidth={660}
      imgLeftPos="auto"
      imgRightPos={[2, 4, 6]}
      abilityDescription="The Doctor is a magic scientist who can work wonders. You can play the Doctor as a traditional healer role, or use its inquisitive abilities to be more like a magical detective. Or, if you’re feeling slightly sinister, you can dabble in some necromancy…"
      abilityImg01={doctor01Image}
      abilityImg02={doctor02Image}
      abilityImg03={doctor03Image}
      abilityImg04={doctor04Image}
      yaml={YAMLData}
    />

    <Pagination parent="roles" prev="naturalist" next="spy" />
  </Layout>
)

export default DoctorPage
